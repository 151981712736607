import axios from '@axios'

export default {
  namespaced: true,
  state: {
    filters: {},
    currentPage: 1,
    total: null,
    perPage: 10,
    clients: [],
    clientData: null,
    active: '',
    client: null,
    courseId: null,
    modalityId: null,
    recent_activity: false,
    studentStats: null,
    startDate: '',
    endDate: '',
    excelLoading: false,
  },
  getters: {
  },
  mutations: {
    onGetClients(state, payload) {
      state.clients = payload.map(item => {
        let style = ''
        if (item.profile) {
          style = item.profile.active ? '' : 'primary'
        }
        const result = {
          _rowVariant: style,
          ...item.profile,
          ...item,
        }
        return result
      })
    },
    onGetClientById(state, payload) {
      state.clientData = payload
    },
    clearClient(state) {
      state.clientData = null
    },
    setTotal(state, payload) {
      state.total = payload
    },
    setPage(state, payload) {
      state.currentPage = payload
    },
    searchFilter(state, payload) {
      state.currentPage = 1
      state.searchFilter = payload
    },
    courseFilter(state, payload) {
      state.currentPage = 1
      state.courseId = payload
    },
    activeUpdate(state, payload) {
      state.currentPage = 1
      state.active = payload
    },
    clientFilteredFor(state, payload) {
      state.currentPage = 1
      state.client = payload
    },
    recentActivity(state, payload) {
      state.currentPage = 1
      state.recent_activity = payload
    },
    onGetStudentStats(state, payload) {
      state.studentStats = payload
    },
    clearStudentStats(state) {
      state.studentStats = null
    },
    modalitiesFilteredFor(state, payload) {
      state.currentPage = 1
      state.modalityId = payload
    },
  },
  actions: {
    getClients({ state, commit }) {
      const recentActivity = state.recent_activity ? encodeURIComponent(state.recent_activity) : ''
      const s = state.searchFilter ? encodeURIComponent(state.searchFilter) : ''
      const active = state.active !== null ? encodeURIComponent(state.active) : ''
      const clientId = state.client !== null ? encodeURIComponent(state.client) : ''
      const courseId = state.courseId !== null ? encodeURIComponent(state.courseId) : ''
      const modalityId = state.modalityId ? encodeURIComponent(state.modalityId) : ''
      axios
        .get(`/users/students/?page=${state.currentPage}&search=${s}&active=${active}&client_id=${clientId}&course_id=${courseId}&recent_activity=${recentActivity}&modality_id=${modalityId}`)
        .then(response => {
          commit('onGetClients', response.data.results)
          commit('setTotal', response.data.count)
        })
    },
    downloadExcel({ state }) {
      const recentActivity = state.recent_activity ? encodeURIComponent(state.recent_activity) : ''
      const s = state.searchFilter ? encodeURIComponent(state.searchFilter) : ''
      const active = state.active !== null ? encodeURIComponent(state.active) : ''
      const clientId = state.client !== null ? encodeURIComponent(state.client) : ''
      const courseId = state.courseId !== null ? encodeURIComponent(state.courseId) : ''
      const modalityId = state.modalityId ? encodeURIComponent(state.modalityId) : ''
      state.excelLoading = true
      return axios.get(`/users/students-excel/?page=${state.currentPage}&search=${s}&active=${active}&client_id=${clientId}&course_id=${courseId}&recent_activity=${recentActivity}&modality_id=${modalityId}`, { responseType: 'blob' })
        .then(response => {
          const file = new Blob(
            [response.data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' },
          )
          const fileName = `alunos_${new Date().toISOString().slice(0, 10)}.xls`
          const fileURL = URL.createObjectURL(file)
          const downloadLink = document.createElement('a')
          downloadLink.href = fileURL
          downloadLink.download = fileName
          downloadLink.click()
          // window.open(fileURL)
          state.excelLoading = false
        })
        .catch(() => {
          state.excelLoading = false
        })
    },
    getClientById({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('clearClient')
        axios
          .get(`/users/students/${payload}/`)
          .then(response => {
            commit('onGetClientById', response.data)
            resolve()
          })
          .catch(() => reject())
      })
    },
    getStudentStats({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/courses/student-data/${payload}/?start_date=${state.startDate}&end_date=${state.endDate}`)
          .then(response => {
            commit('onGetStudentStats', response.data)
            resolve()
          })
          .catch(() => reject())
      })
    },
    updateClient(context, payload) {
      if (payload.id !== undefined) {
        return new Promise((resolve, reject) => {
          axios
            .put(`/users/students/${payload.id}/`, { ...payload })
            .then(response => {
              resolve(response)
            })
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/users/students/', { ...payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    registerClient(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users/register/', { ...payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteClient(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/students/${payload.id}/`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
