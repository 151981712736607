import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cancellation: [
      {
        value: 'CL',
        text: 'Clima',
      },
      {
        value: 'CA',
        text: 'Cancelado pela administração',
      },
      {
        value: 'FR',
        text: 'Feriado',
      },
      {
        value: 'FP',
        text: 'Falta professor',
      },
      {
        value: 'MN',
        text: 'Manutenção do espaço',
      },
    ],
    states: [
      'AC',
      'AL',
      'AM',
      'AP',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MG',
      'MS',
      'MT',
      'PA',
      'PB',
      'PE',
      'PI',
      'PR',
      'RJ',
      'RN',
      'RO',
      'RR',
      'RS',
      'SC',
      'SE',
      'SP',
      'TO',
    ],
    filters: {},
    events: [],
    event: {},
    courses: [],
    modalities: [],
    professors: [],
    clients: [],
    students: [],
    admins: [],
  },
  getters: {
  },
  mutations: {
    removeFromRegisteredStudents(state, studentId) {
      state.event.students_registered = state.event.students_registered.filter(student => student.id !== studentId)
    },
    onGetEvents(state, payload) {
      state.events = payload
    },
    onGetEventById(state, payload) {
      state.event = payload
      state.event.validated = true
    },
    onGetModalities(state, payload) {
      state.modalities = payload.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
    },
    updateProfessorsList(state, payload) {
      const fullList = state.professors.concat(payload)
      state.professors = fullList.filter((item, index) => fullList.map(o => o.id).indexOf(item.id) === index)
    },
    updateClientsList(state, payload) {
      const fullList = state.clients.concat(payload)
      state.clients = fullList.filter((item, index) => fullList.map(o => o.id).indexOf(item.id) === index)
    },
    updateStudentsList(state, { start, data }) {
      let fullList = []
      if (start) {
        fullList = data
      } else {
        fullList = state.students.concat(data)
      }
      state.students = fullList.filter((item, index) => fullList.map(o => o.id).indexOf(item.id) === index)
    },
    addStudentsToEvent(state, students) {
      state.event.students_present = students
    },
  },
  actions: {
    getEvents({ commit }, filters) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/courses/events/?start_date=${filters.startDate}&end_date=${filters.endDate}&professors=${filters.professors.join()}&locals=${filters.clients.join()}&modalities=${filters.modalities.join()}&status=${filters.status}&page=${filters.page}&ordering=${filters.ordering}`)
          .then(response => {
            commit('onGetEvents', response.data.results)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getModalities({ commit }) {
      axios
        .get('/courses/modalities/')
        .then(response => {
          commit('onGetModalities', response.data)
        })
    },
    updateModality(state, payload) {
      if (payload.id !== undefined) {
        return new Promise((resolve, reject) => {
          axios
            .put(`/courses/modalities/${payload.id}/`, { ...payload })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/courses/modalities/', { ...payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteModality(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/courses/modalities/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getEventById({ commit }, eventId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/courses/events/${eventId}/`)
          .then(response => {
            commit('onGetEventById', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getProfessors({ commit, dispatch }, nextPage = null) {
      if (nextPage !== null) {
        axios
          .get(nextPage)
          .then(response => {
            commit('updateProfessorsList', response.data.results)
            if (response.data.next) {
              dispatch('getProfessors', response.data.next)
            }
          })
      } else {
        axios
          .get('/users/professors/')
          .then(response => {
            commit('updateProfessorsList', response.data.results)
            if (response.data.next) {
              dispatch('getProfessors', response.data.next)
            }
          })
      }
    },
    getClients({ commit, dispatch }, nextPage = null) {
      if (nextPage !== null) {
        axios
          .get(nextPage)
          .then(response => {
            commit('updateClientsList', response.data.results)
            if (response.data.next) {
              dispatch('getClients', response.data.next)
            }
          })
      } else {
        axios
          .get('/users/clients/')
          .then(response => {
            commit('updateClientsList', response.data.results)
            if (response.data.next) {
              dispatch('getClients', response.data.next)
            }
          })
      }
    },
    getStudents({ state, commit, dispatch }, { courseId = null, clientId = null, nextPage = null }) {
      if (nextPage !== null) {
        axios
          .get(nextPage)
          .then(response => {
            commit('updateStudentsList', { start: false, data: response.data.results })
            if (response.data.next) {
              dispatch('getStudents', { clientId: null, nextPage: response.data.next })
            }
          })
      } else {
        /* eslint-disable */
        if (clientId === null && courseId === null) {
          state.students = []
        } else {
          axios
            .get(`/users/students/?client_id=${clientId === -1 || clientId === null ? '' : clientId}&course_id=${courseId === -1 || courseId === null ? '' : courseId}`)
            .then(response => {
              commit('updateStudentsList', { start: true, data: response.data.results })
              if (response.data.next) {
                dispatch('getStudents', { clientId: null, nextPage: response.data.next })
              }
            })
        }
      }
    },
    updateEvent(context, event) {
      return axios.put(
        `/courses/events/${event.id}/`,
        event
      )
    }
  },
}
