import axios from '@axios'

export default {
  namespaced: true,
  state: {
    filters: {},
    currentPage: 1,
    total: null,
    perPage: 10,
    clients: [],
    diasDaSemana: [
      {
        text: 'Segunda-Feira',
        value: 0,
      },
      {
        text: 'Terça-Feira',
        value: 1,
      },
      {
        text: 'Quarta-Feira',
        value: 2,
      },
      {
        text: 'Quinta-Feira',
        value: 3,
      },
      {
        text: 'Sexta-Feira',
        value: 4,
      },
      {
        text: 'Sábado',
        value: 5,
      },
      {
        text: 'Domingo',
        value: 6,
      },
    ],
    clientData: null,
    stats: [],
  },
  getters: {
  },
  mutations: {
    onGetClients(state, payload) {
      state.clients = payload
    },
    onGetClientById(state, payload) {
      state.clientData = payload
    },
    onGetStats(state, payload) {
      state.stats = payload
    },
    clearClient(state) {
      state.clientData = null
    },
    setTotal(state, payload) {
      state.total = payload
    },
    setPage(state, payload) {
      state.currentPage = payload
    },
  },
  actions: {
    getClients({ state, commit }, filters) {
      const client = filters.client ? encodeURIComponent(filters.client) : ''
      const modalities = filters.modalities ? encodeURIComponent(filters.modalities) : ''
      const day = (filters.day || filters.day === 0) ? encodeURIComponent(filters.day) : ''
      axios
        .get(`/courses/courses/?page=${state.currentPage}&day=${day}&modalities=${modalities}&locals=${client}`)
        .then(response => {
          commit('onGetClients', response.data.results)
          commit('setTotal', response.data.count)
        })
    },
    getClientById({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('clearClient')
        axios
          .get(`/courses/courses/${payload}/`)
          .then(response => {
            commit('onGetClientById', response.data)
            resolve()
          })
          .catch(() => reject())
      })
    },
    updateClient(context, payload) {
      if (payload.id !== undefined) {
        return new Promise((resolve, reject) => {
          axios
            .put(`/courses/courses/${payload.id}/`, { ...payload })
            .then(response => {
              resolve(response)
            })
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/courses/courses/', { ...payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteClient(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/courses/courses/${payload.id}/`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getStats({ commit }, filters) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/courses/stats/${filters.selectedMonth.slice(0, 4)}/${filters.selectedMonth.slice(5, 7)}/?start_date=${filters.startDate}&end_date=${filters.endDate}&professors=${filters.professors.join()}&locals=${filters.clients.join()}&modalities=${filters.modalities.join()}&status=${filters.status}`)
          .then(response => {
            commit('onGetStats', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
