import Vue from 'vue'

// axios
import axios from 'axios'

const apiRoot = process.env.VUE_APP_API_ROOT || 'https://quales.com.br/api/'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: apiRoot,
//   timeout: 1000000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
