export default [
  // {
  //   path: '/faq',
  //   name: 'faq',
  //   component: () => import('@/views/pages/faq/Faq.vue'),
  //   meta: {
  //     resource: 'admin',
  //     action: 'read',
  //   },
  // },
]
