import axios from '@axios'

export default {
  namespaced: true,
  state: {
    filters: {},
    currentPage: 1,
    total: null,
    perPage: 10,
    clients: [],
    clientData: null,
  },
  getters: {
  },
  mutations: {
    onGetClients(state, payload) {
      state.clients = payload.map(item => {
        const result = {
          ...item.profile,
          ...item,
        }
        return result
      })
    },
    onGetClientById(state, payload) {
      state.clientData = payload
    },
    clearClient(state) {
      state.clientData = null
    },
    setTotal(state, payload) {
      state.total = payload
    },
    setPage(state, payload) {
      state.currentPage = payload
    },
    searchFilter(state, payload) {
      state.currentPage = 1
      state.searchFilter = payload
    },
  },
  actions: {
    getClients({ state, commit }) {
      const s = state.searchFilter ? encodeURIComponent(state.searchFilter) : ''
      axios
        .get(`/users/admins/?page=${state.currentPage}&search=${s}`)
        .then(response => {
          commit('onGetClients', response.data.results)
          commit('setTotal', response.data.count)
        })
    },
    getClientById({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('clearClient')
        axios
          .get(`/users/admins/${payload}/`)
          .then(response => {
            commit('onGetClientById', response.data)
            resolve()
          })
          .catch(() => reject())
      })
    },
    updateClient(context, payload) {
      if (payload.id !== undefined) {
        return new Promise((resolve, reject) => {
          axios
            .put(`/users/admins/${payload.id}/`, { ...payload })
            .then(response => {
              resolve(response)
            })
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/users/admins/', { ...payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteClient(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/admins/${payload.id}/`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
