import jwtDecode from 'jwt-decode'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    userData: null,
  },
  getters: {
    userData: state => state.userData,
  },
  mutations: {
    onLogin(state, payload) {
      state.userData = payload
    },
    onLogout(state) {
      state.userData = null
    },
  },
  actions: {
    login({ state, commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        useJwt.login({
          email,
          password,
        }).then(response => {
          const userData = jwtDecode(response.data.access)
          useJwt.setToken(response.data.access)
          useJwt.setRefreshToken(response.data.refresh)
          commit('onLogin', userData)
          resolve(state.userData.role)
        })
          .catch(error => reject(error))
      })
    },
    logout({ commit }) {
      commit('onLogout')
      useJwt.logout()
    },
  },
}
