import axios from '@axios'

export default {
  namespaced: true,
  state: {
    filters: {},
    currentPage: 1,
    total: null,
    perPage: 10,
    clients: [],
    clientData: null,
    citiesOptions: [],
    searchFilter: '',
    cityFilter: '',
    clientStats: null,
    startDate: '',
    endDate: '',
  },
  getters: {
  },
  mutations: {
    onGetClients(state, payload) {
      state.clients = payload.map(item => {
        const result = {
          ...item.profile,
          ...item,
        }
        return result
      })
    },
    onGetClientById(state, payload) {
      state.clientData = payload
    },
    clearClient(state) {
      state.clientData = null
    },
    setTotal(state, payload) {
      state.total = payload
    },
    setPage(state, payload) {
      state.currentPage = payload
    },
    cityFilter(state, payload) {
      state.currentPage = 1
      state.cityFilter = payload
    },
    searchFilter(state, payload) {
      state.currentPage = 1
      state.searchFilter = payload
    },
    onGetCitiesOptions(state, payload) {
      state.citiesOptions = payload
    },
    onGetClientStats(state, payload) {
      state.clientStats = payload
    },
    clearClientStats(state) {
      state.clientStats = null
    },
  },
  actions: {
    getClients({ state, commit }) {
      const s = state.searchFilter ? encodeURIComponent(state.searchFilter) : ''
      const c = state.cityFilter ? encodeURIComponent(state.cityFilter) : ''
      axios
        .get(`/users/clients/?page=${state.currentPage}&search=${s}&city=${c}`)
        .then(response => {
          commit('onGetClients', response.data.results)
          commit('setTotal', response.data.count)
        })
    },
    getClientById({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('clearClient')
        axios
          .get(`/users/clients/${payload}/`)
          .then(response => {
            commit('onGetClientById', response.data)
            resolve()
          })
          .catch(() => reject())
      })
    },
    getClientStats({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/courses/client-data/${payload}/?start_date=${state.startDate}&end_date=${state.endDate}`)
          .then(response => {
            commit('onGetClientStats', response.data)
            resolve()
          })
          .catch(() => reject())
      })
    },
    getClientInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('clearClient')
        axios
          .get(`/users/client-info/${payload}/`)
          .then(response => {
            commit('onGetClientById', response.data)
            resolve()
          })
          .catch(() => reject())
      })
    },
    updateClient(context, payload) {
      if (payload.id !== undefined) {
        return new Promise((resolve, reject) => {
          axios
            .put(`/users/clients/${payload.id}/`, { ...payload })
            .then(response => {
              resolve(response)
            })
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/users/clients/', { ...payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteClient(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/clients/${payload.id}/`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getCitiesOptions({ commit }) {
      axios
        .get('/users/cities/clients/')
        .then(response => {
          commit('onGetCitiesOptions', response.data)
        })
    },
  },
}
