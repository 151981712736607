export default [
  {
    path: '/historico',
    name: 'history',
    component: () => import('@/views/courses/History.vue'),
    meta: {
      resource: 'history',
      action: 'read',
    },
  },
  {
    path: '/historico/:id',
    name: 'event-edit',
    component: () => import('@/views/courses/EventEdit.vue'),
    props(route) {
      const props = { ...route.params }
      props.eventId = +props.id
      return props
    },
    meta: {
      resource: 'professor',
      action: 'read',
      navActiveLink: 'history',
    },
  },
  {
    path: '/modalidades',
    name: 'modalidades',
    component: () => import('@/views/courses/Modalities.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
    },
  },
  {
    path: '/aulas',
    name: 'courses',
    component: () => import('@/views/courses/CourseList.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
    },
  },
  {
    path: '/aulas/edit/:id',
    name: 'courses-edit',
    component: () => import('@/views/courses/CourseEdit.vue'),
    props(route) {
      const props = { ...route.params }
      props.userId = +props.id
      return props
    },
    meta: {
      resource: 'admin',
      action: 'read',
      navActiveLink: 'courses',
    },
  },
  {
    path: '/aulas/edit/',
    name: 'courses-add',
    component: () => import('@/views/courses/CourseEdit.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
      navActiveLink: 'courses',
    },
  },
  // Clients
  {
    path: '/clientes/list',
    name: 'clients-list',
    component: () => import('@/views/users/clients/UsersList.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/clientes/report/:id',
    name: 'client-report',
    component: () => import('@/views/users/clients/Report.vue'),
    props(route) {
      const props = { ...route.params }
      props.userId = +props.id
      return props
    },
    meta: {
      resource: 'student',
      action: 'read',
      navActiveLink: 'clients-list',
    },
  },
  {
    path: '/clientes/edit/:id',
    name: 'client-edit',
    component: () => import('@/views/users/clients/UserEdit.vue'),
    props(route) {
      const props = { ...route.params }
      props.userId = +props.id
      return props
    },
    meta: {
      resource: 'client',
      action: 'read',
      navActiveLink: 'clients-list',
    },
  },
  {
    path: '/clientes/edit/',
    name: 'client-add',
    component: () => import('@/views/users/clients/UserEdit.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
      navActiveLink: 'clients-list',
    },
  },
  // Students
  {
    path: '/alunos/list',
    name: 'students-list',
    component: () => import('@/views/users/students/UsersList.vue'),
    meta: {
      resource: 'student',
      action: 'read',
    },
  },
  {
    path: '/alunos/edit/:id',
    name: 'students-edit',
    component: () => import('@/views/users/students/UserEdit.vue'),
    props(route) {
      const props = { ...route.params }
      props.userId = +props.id
      return props
    },
    meta: {
      resource: 'student',
      action: 'read',
      navActiveLink: 'students-list',
    },
  },
  {
    path: '/alunos/edit/',
    name: 'students-add',
    component: () => import('@/views/users/students/UserEdit.vue'),
    meta: {
      resource: 'student',
      action: 'read',
      navActiveLink: 'students-list',
    },
  },
  {
    path: '/alunos/report/:id',
    name: 'students-report',
    component: () => import('@/views/users/students/Report.vue'),
    props(route) {
      const props = { ...route.params }
      props.userId = +props.id
      return props
    },
    meta: {
      resource: 'student',
      action: 'read',
      navActiveLink: 'students-list',
    },
  },
  {
    path: '/cadastro/:clientId',
    name: 'students-register',
    component: () => import('@/views/users/students/UserRegistration.vue'),
    props(route) {
      const props = { ...route.params }
      props.clientId = +props.clientId
      return props
    },
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/cadastro-realizado/:clientId',
    name: 'cadastro-realizado',
    component: () => import('@/views/users/students/UserRegistered.vue'),
    props(route) {
      const props = { ...route.params }
      props.clientId = +props.clientId
      return props
    },
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  // Professors
  {
    path: '/professores/list',
    name: 'professors-list',
    component: () => import('@/views/users/professors/UsersList.vue'),
    meta: {
      resource: 'professor',
      action: 'read',
    },
  },
  {
    path: '/professores/edit/:id',
    name: 'professors-edit',
    component: () => import('@/views/users/professors/UserEdit.vue'),
    props(route) {
      const props = { ...route.params }
      props.userId = +props.id
      return props
    },
    meta: {
      resource: 'professor',
      action: 'read',
      navActiveLink: 'professors-list',
    },
  },
  {
    path: '/professores/edit/',
    name: 'professors-add',
    component: () => import('@/views/users/professors/UserEdit.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
      navActiveLink: 'professors-list',
    },
  },
  // Admins
  {
    path: '/admins/list',
    name: 'admins-list',
    component: () => import('@/views/users/admins/UsersList.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
    },
  },
  {
    path: '/admins/edit/:id',
    name: 'admins-edit',
    component: () => import('@/views/users/admins/UserEdit.vue'),
    props(route) {
      const props = { ...route.params }
      props.userId = +props.id
      return props
    },
    meta: {
      resource: 'admin',
      action: 'read',
      navActiveLink: 'admins-list',
    },
  },
  {
    path: '/admins/edit/',
    name: 'admins-add',
    component: () => import('@/views/users/admins/UserEdit.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
      navActiveLink: 'admins-list',
    },
  },
]
