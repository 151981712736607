import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { getUserData, isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

import auth from './routes/auth'
import faq from './routes/faq'
import quales from './routes/quales'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      meta: {
        resource: 'nobody',
        action: 'read',
        redirectIfLoggedIn: true,
      },
    },
    ...auth,
    ...faq,
    ...quales,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData()
      return next(getHomeRouteForLoggedInUser(userData.role))
    }
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData.role))
  }

  return next()
})

export default router
