import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const { jwt } = useJwt(
  axios,
  {
    // Endpoints
    loginEndpoint: 'users/token/',
    registerEndpoint: '/jwt/register',
    refreshEndpoint: 'users/token/refresh/',
    logoutEndpoint: '/jwt/logout',

    // This will be prefixed in authorization header with token
    // e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',

    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'access',
    storageRefreshTokenKeyName: 'refresh',
  },
)
export default jwt
