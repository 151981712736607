import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import quales from './quales'
import clients from './clients'
import admins from './admins/index'
import professors from './professors'
import students from './students'
import courses from './courses'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    user: state.user,
    // quales: state.quales,
  }),
  filter: mutation => [
    'user/onLogin',
    'user/onLogout',
  ].includes(mutation.type),
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    quales,
    clients,
    students,
    professors,
    admins,
    courses,
  },
  // strict: process.env.DEV,
  plugins: [vuexLocal.plugin],
})
